// @flow

import React, { Component } from 'react';
import Restaurant from './Restaurant';
import { connect } from 'react-redux';
import { setUserId } from '../actions/settingsAction';
import { setCustomerNb, resetCart } from '../actions/cartAction';
import { firestore, database } from '../services/firebase'

const cartObject = {
  createdAt: null,
  canOrder: true,
  products: [],
  table: '',
  total: 0,
  totalConfirm: 0,
  totalProducts: 0,
  subtotal: 0,
  discount: 0,
  orderSchedule: [],
}

const userCartObject = {
  createdAt: null,
  canOrder: true,
  products: [],
  table: '',
  total: 0,
  totalConfirm: 0,
  totalProducts: 0,
  subtotal: 0,
  discount: 0,
}

class Restaurantdatabase extends Component {
  unsubscribe: Object

  constructor() {
    super();

    this.unsubscribe = null

    this.state = {
      cart: {
        products: [],
        qrCode: null,
        invalidQrCode: false,
        error: '',
      },
      myCart: {
        products: [],
      },
      users: [],
      productHistory: []
    };
  }

  componentDidMount() {
    const qrid = this.props.match.params.qrid
    const restoId = qrid.split('-')[0];
    const qrId = qrid.split('-')[1];
    this.handleTableQr(restoId, qrId);
  }

  componentWillUnmount() {
    if (this.unsubscribe) this.unsubscribe()
  }

  handleTableQr = async (restoId, qrId) => {
    const ref = firestore().collection(`Restaurant/${restoId}/QRCode`).doc(qrId);
    const doc = await ref.get();
    try {
      if (!doc.exists) {
        this.setState({ invalidQrCode: true })
        window.localStorage.removeItem('tableSessionId')
        if (window.ReactNativeWebView !== undefined) {
          this.props.resetCart();
          window.ReactNativeWebView.postMessage("")
        }
        return false;
      } else {
        const qrCode = doc.data();
        if (!qrCode.status) {
          this.setState({ invalidQrCode: true })
          window.localStorage.removeItem('tableSessionId')
          if (window.ReactNativeWebView !== undefined) {
            this.props.resetCart();
            window.ReactNativeWebView.postMessage("")
          }
          return false;
        }
        if (qrCode.status === 3) {
          this.setState({ expiredQrCode: true })
          window.localStorage.removeItem('tableSessionId')
          if (window.ReactNativeWebView !== undefined) {
            this.props.resetCart();
            window.ReactNativeWebView.postMessage("")
          }
          return false;
        }
        qrCode.id = doc.id;
        this.props.setCustomerNb(parseFloat(qrCode.maxCustomer) ?? 1)
        this.setState({ qrCode });

        // get user id
        let userId = window.localStorage.getItem('tableSessionId');
        const usersRef = database().ref(`${restoId}/${qrId}/users`);
        // const users = (await usersRef.once('value')).val() || {};
        // const userIds = Object.keys(users);

        if (!userId) {
          const pushRef = usersRef.push();
          const pushKey = pushRef.key;
          window.localStorage.setItem('tableSessionId', pushKey);
        }

        this.props.setUserId(userId)

        database().ref(`${restoId}/${qrId}/users/${userId}/cart`).on('value', (snap) => {
          let val = snap.val() || {};
          if (!snap.val()) {
            database().ref(`${restoId}/${qrId}/users/${userId}/cart`).set({ ...cartObject, table: qrCode.table })
            val = { ...cartObject, table: qrCode.table }
          }

          this.setState({ cart: val })
        });

        database().ref(`${restoId}/${qrId}/users/${userId}/productHistory`).on('value', (snap) => {
          const val = snap.val() || [];
          this.setState({ productHistory: val })
        });

        database().ref(`${restoId}/${qrId}/users/`).on('value', (snap) => {
          const users = snap.val() || {};
          const userIds = Object.keys(users);
          this.setState({ users: userIds })
        })

        database().ref(`${restoId}/${qrId}/users/${userId}/cart`).on('value', (snap) => {
          let val = snap.val() || {};
          if (!snap.val()) {
            database().ref(`${restoId}/${qrId}/users/${userId}/cart`).set(userCartObject)
          }

          if (!val.products) {
            val = userCartObject
          }

          this.setState({ myCart: val })
        });

        return true;
      }
    }
    catch (err) {
      console.log(err)
      this.setState({ invalidQrCode: true })
      if (window.ReactNativeWebView !== undefined) {
        this.props.resetCart();
        window.ReactNativeWebView.postMessage("")
      }
    }
  }

  render() {
    if (this.state.invalidQrCode) {
      return (
        <div className="center-loading">
          <p>TABLE INVALIDE.</p>
        </div>
      )
    }

    if (this.state.expiredQrCode) {
      return (
        <div className="center-loading">
          <p>COMMANDE TERMINEE.</p>
        </div>
      )
    }

    if (this.state.error) {
      return (
        <div className="center-loading">
          {this.state.error}
        </div>
      )
    }


    return (
      <Restaurant
        {...this.props}
        users={this.state.users}
        qrCode={this.state.qrCode}
        cart={this.state.cart}
        myCart={this.state.myCart}
        productHistory={this.state.productHistory}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    settings: state.settings,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setUserId: (id) => dispatch(setUserId(id)),
    setCustomerNb: (nb) => dispatch(setCustomerNb(nb)),
    resetCart: () => dispatch(resetCart()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Restaurantdatabase);
