const config = {
  apiKey: "AIzaSyBcqgS1V5IndTOM0pG3Ur5JEpsEZR49LSY",
  authDomain: "we-kiosk.firebaseapp.com",
  databaseURL: "https://we-kiosk.firebaseio.com",
  projectId: "we-kiosk",
  storageBucket: "we-kiosk.appspot.com",
  messagingSenderId: "155395335863",
  appId: "1:155395335863:web:b8ce941a190fb072d24f32",
  measurementId: "G-PY77218DP1"
};

export default config