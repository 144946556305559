/**
 * @format
 * @flow
 */

import React from 'react';
import '../styles/ProductCartRow.scss'
import { connect } from 'react-redux';
import Select from 'react-select'
class ProductCartRow extends React.Component {
  defaultQuantity: number

  constructor(props: any) {
    super(props)
    if (this.props.product.maxQuantity && this.props.productHistory[this.props.product.id]) {
      const maxQuantity = this.props.product.maxQuantity * this.props.cart.customerNb
      this.defaultQuantity = maxQuantity - this.props.productHistory[this.props.product.id]
    }
  }

  deleteProduct = async () => {
    await this.props.deleteProduct(this.props.index, this.props.product)
    await this.getCartPrice()
  }

  updateProduct = async (newQuantity) => {
    const { product, index } = this.props
    const { total, subtotal } = this.getProductPrice(product, newQuantity, true)
    const newProduct = {
      ...product,
      quantity: newQuantity,
      total,
      subtotal,
    }

    await this.props.updateProduct(newProduct, index, product.quantity)
    await this.getCartPrice()
  }

  getCartPrice = () => {
    const cart = this.props.myCart
    if (cart.products.length === 0) {
      this.props.updateCartTotal(0, 0, 0, 0, 0)
      return
    }
    let cartTotal = 0
    let cartTotalProducts = 0
    let cartTotalFreeProducts = 0
    let cartSubtotal = 0

    cart.products.forEach((product) => {
      const { total, subtotal } = this.getProductPrice(product, product.quantity, true)
      cartTotal += total
      cartTotalProducts += product.quantity
      cartSubtotal += subtotal
      if (total === 0) {
        cartTotalFreeProducts += product.quantity
      }
    })
    this.props.updateCartTotal(cartTotal, cartTotalProducts, cartSubtotal, cartTotalFreeProducts)
  }

  getProductPrice = (product: ProductType, quantity: number, forTotal: boolean): { total: number, subtotal: number } => {
    const rubric = product.rubric

    if (typeof product === 'undefined') return { total: 0, totalVat: 0 }
    let total = quantity * product.unitPrice

    rubric?.forEach((section) => {
      section.data.forEach((rubric) => {
        if (rubric.checked && rubric.unitPrice > 0) {
          if (section.allowSameMultiple) {
            total += quantity * rubric.unitPrice * (rubric.quantity || 0)
          }
          else {
            total += quantity * rubric.unitPrice
          }
        }
      })
    })

    if (product.tableDiscount && product.tableDiscount.discount) {
      total = total * (1 - product.tableDiscount.discount / 100)
    }

    let subtotal = total

    // apply cart discount
    if (this.props.cart.discount) {
      if (!product.tableDiscount || (product.tableDiscount && product.tableDiscount.canDiscount)) {
        total = total * (1 - this.props.cart.discount / 100)
      }
    }

    return { total, subtotal }
  }


  handleQuantityChange = (event) => {
    let newQuantity = parseInt(event.target.value)
    if (newQuantity === this.props.product.quantity) return
    else if (newQuantity === 0) {
      this.deleteProduct()
    }
    else if (newQuantity !== this.props.product.quantity) {
      this.updateProduct(newQuantity)
    }
  }

  render() {
    let defaultQuantity = 50
    if (this.props.product.roundQuantity) {
      const products = this.props.myCart.products ? [].concat.apply([], Object.values(this.props.myCart.products)) : []
      let cartProducts = products ? products.filter(e => e.id === this.props.product.id) : [];
      let totalQuantity = cartProducts.length > 0 ? cartProducts.reduce((total, p) => total + p.quantity, 0) : 0;
      let roundQuantity = this.props.product.roundQuantity * this.props.cart.customerNb
      defaultQuantity = this.props.product.quantity + roundQuantity - totalQuantity + 1
    }

    if (this.props.product.maxQuantity) {
      const products = this.props.myCart.products ? [].concat.apply([], Object.values(this.props.myCart.products)) : []

      let cartProducts = products ? products.filter(e => e.id === this.props.product.id) : [];
      let totalQuantity = cartProducts.length > 0 ? cartProducts.reduce((total, p) => total + p.quantity, 0) : 0;
      if (this.props.productHistory && this.props.productHistory[this.props.product.id])
        totalQuantity += this.props.productHistory[this.props.product.id]

      let maxQuantity = this.props.product.maxQuantity * this.props.cart.customerNb

      if (defaultQuantity > this.props.product.quantity + maxQuantity - totalQuantity + 1)
        defaultQuantity = this.props.product.quantity + maxQuantity - totalQuantity + 1
    }

    if (defaultQuantity <= 0) defaultQuantity = 2

    return (
      <div className="cart-product-item" onClick={this.props.onClick(this.props.index)}>
        <div className="cart-product-row">
          <div className="cart-row">
            <div className="text-number-product">
              {this.props.editable === false && (
                this.props.product.quantity
              )}
              {(this.props.editable === undefined || this.props.editable === true) && (
                <select value={this.props.product.quantity} onClick={(e) => { e.stopPropagation(); }} onChange={this.handleQuantityChange} width="50" style={{ width: 50 }}>
                  {Array.apply(null, Array(defaultQuantity)).map((o, idx) =>
                    <option key={idx} value={idx}>{idx}</option>
                  )}
                </select>
              )}

            </div>
            <div className="cart-text">{this.props.product.name}</div>
          </div>
          <div className="cart-text">{`${this.props.product.subtotal.toFixed(2)} €`}</div>
        </div>
        {(this.props.product.rubric || []).map((rubric, index) => {
          if (rubric.data.length > 0)
            return (
              <div key={index} className="cart-rubric-list">
                {rubric.data.map((r, index) => {
                  if (r.checked)
                    return (
                      <div className="rubric-text" key={index}>
                        {r.name} {r.unitPrice > 0 ? '(' + r.unitPrice.toFixed(2) + '€)' : ''} {r.quantity && 'x' + r.quantity}
                      </div>
                    );
                  return null
                })}
              </div>
            );
          else return null;
        })}
      </div>
    )
  }
}

// redux
const mapStateToProps = (state) => {
  return {
    cart: state.cart
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    // updateProduct: (product, idx) => dispatch(updateProduct(product, idx)),
    // updateCartTotal: (cartTotal, cartTotalHT, cartTotalVat, cartTotalProducts, cartSubtotal) => dispatch(updateCartTotal(cartTotal, cartTotalHT, cartTotalVat, cartTotalProducts, cartSubtotal)),
    // deleteProduct: (idx) => dispatch(deleteProduct(idx))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductCartRow);