import React from 'react';
import { connect } from 'react-redux';
import { database } from '../services/firebase'
import ProductCartRow from './ProductCartRow'


class ProductCartRowRealtime extends React.Component {
  updateProduct = async (product, index, oldQuantity) => {
    const { userId, restoId, qrId } = this.props
    database().ref(`${restoId}/${qrId}/users/${userId}/cart/products/${index}`).set(product);
  }

  updateCartTotal = (total, totalProducts, subtotal, totalFreeProducts) => {
    const { userId, restoId, qrId } = this.props
    database().ref(`${restoId}/${qrId}/users/${userId}/cart/`).update({
      total, totalProducts, subtotal, totalFreeProducts
    })
  }

  deleteProduct = async (index, product) => {
    const { userId, restoId, qrId } = this.props
    this.props.myCart.products.splice(index, 1);
    database().ref(`${restoId}/${qrId}/users/${userId}/cart/products/`).set(this.props.myCart.products);
  }

  render() {
    return (
      <ProductCartRow
        {...this.props}
        updateProduct={this.updateProduct}
        updateCartTotal={this.updateCartTotal}
        deleteProduct={this.deleteProduct}
      />
    );
  }
}

// redux
const mapStateToProps = (state) => {
  return {
    restoId: state.settings.restoId,
    qrId: state.settings.qrId,
    userId: state.settings.userId
  }
}

export default connect(mapStateToProps)(ProductCartRowRealtime);
