export const ADD_PRODUCT = "ADD_PRODUCT"
export const DELETE_PRODUCT = "DELETE_PRODUCT"
export const UPDATE_COMMENT = "UPDATE_COMMENT"
export const UPDATE_PRODUCT = "UPDATE_PRODUCT"
export const UPDATE_CART_TOTAL = "UPDATE_CART_TOTAL"
export const UPDATE_RESTO_INFO = "UPDATE_RESTO_INFO"
export const SET_FLUSH_CART = "SET_FLUSH_CART"
export const RESET_CART = "RESET_CART"
export const APPLY_DISCOUNT = "APPLY_DISCOUNT"
export const RETURN_CART_FEES = "RETURN_CART_FEES"
export const SET_CART_TOTAL_CONFIRM = "SET_CART_TOTAL_CONFIRM"
export const APPLY_COUPON = "APPLY_COUPON"
export const PICKED_ORDER_TYPE = "PICKED_ORDER_TYPE"
export const UPDATE_TABLE = "UPDATE_TABLE"
export const SET_CUSTOMER_NB = "SET_CUSTOMER_NB"
export const SET_ORDER_COUNT = "SET_ORDER_COUNT"
export const SET_ORDER_DATETIME = "SET_ORDER_DATETIME"

export function addProduct(product) {
  return {
    type: ADD_PRODUCT,
    payload: {
      product
    }
  }
}

export function updateComment(comment) {
  return {
    type: UPDATE_COMMENT,
    payload: {
      comment
    }
  }
}

export function updateProduct(product, idx) {
  return {
    type: UPDATE_PRODUCT,
    payload: {
      product,
      idx
    }
  }
}

export function deleteProduct(idx) {
  return {
    type: DELETE_PRODUCT,
    payload: {
      idx
    }
  }
}

export function updateCartTotal(cartTotal, cartTotalProducts, cartSubtotal) {
  return {
    type: UPDATE_CART_TOTAL,
    payload: {
      cartTotal,
      cartTotalProducts,
      cartSubtotal,
    }
  }
}

export function updateRestoInfo(restoId, restoName, restoPostal, canOrder, restoInformations) {
  return {
    type: UPDATE_RESTO_INFO,
    payload: {
      restoId,
      restoName,
      restoPostal,
      canOrder,
      restoInformations,
    }
  }
}

export function applyDiscount(discount) {
  return {
    type: APPLY_DISCOUNT,
    payload: {
      discount,
    }
  }
}

export function updateCartFees(discountTotal, feeTotal) {
  return {
    type: RETURN_CART_FEES,
    payload: {
      discountTotal,
      feeTotal,
    }
  }
}

export function setFlushCart() {
  return {
    type: SET_FLUSH_CART
  }
}

export function resetCart() {
  return {
    type: RESET_CART
  }
}

export function setCartTotalConfirm(totalConfirm) {
  return {
    type: SET_CART_TOTAL_CONFIRM,
    payload: {
      totalConfirm
    }
  }
}

export function applyCoupon(coupon) {
  return {
    type: APPLY_COUPON,
    payload: {
      coupon,
    }
  }
}

export function updateTable(table) {
  return {
    type: UPDATE_TABLE,
    payload: {
      table
    }
  }
}

export function setCustomerNb(customerNb) {
  return {
    type: SET_CUSTOMER_NB,
    payload: {
      customerNb
    }
  }
}

export function setOrderCount() {
  return {
    type: SET_ORDER_COUNT,
    payload: {}
  }
}

export function setOrderDatetime() {
  return {
    type: SET_ORDER_DATETIME,
    payload: {}
  }
}