/**
 * @format
 * @flow
 */

import {
  UPDATE_SETTINGS,
  UPDATE_BUFFET_SETTINGS,
  SET_RESTO_ID,
  SET_QR_ID,
  SET_USER_ID
} from '../actions/settingsAction';
import { SettingsType } from '../flowObjectType'

const settingsObject: SettingsType = {
  id: '',
  color: '',
  autoAcceptOrder: false,
  table: false,
  buffetSettings: {},
  restoId: '',
  qrId: '',
  userId: '',
}

export default function foo(state: Object = { ...settingsObject }, action: Object) {
  switch (action.type) {
    case UPDATE_SETTINGS:
      return {
        ...state,
        id: action.payload.id,
        color: action.payload.color,
        autoAcceptOrder: action.payload.autoAcceptOrder,
        table: action.payload.table,
      }
    case UPDATE_BUFFET_SETTINGS:
      return { ...state, buffetSettings: action.payload.buffetSettings };
    case SET_RESTO_ID: {
      return {
        ...state,
        restoId: action.payload.restoId
      }
    }
    case SET_QR_ID: {
      return {
        ...state,
        qrId: action.payload.qrId
      }
    }
    case SET_USER_ID: {
      return {
        ...state,
        userId: action.payload.userId
      }
    }
    default:
      return state;
  }
}
