export const UPDATE_SETTINGS = "UPDATE_SETTINGS"
export const UPDATE_BUFFET_SETTINGS = "UPDATE_BUFFET_SETTINGS"
export const SET_RESTO_ID = "SET_RESTO_ID"
export const SET_QR_ID = "SET_QR_ID"
export const SET_USER_ID = "SET_USER_ID"

export function updateRestoSettings(id, color, autoAcceptOrder) {
  return {
    type: UPDATE_SETTINGS,
    payload: {
      id,
      color,
      autoAcceptOrder
    }
  }
}

export function updateBuffetSettings(buffetSettings) {
  return {
    type: UPDATE_BUFFET_SETTINGS,
    payload: {
      buffetSettings
    }
  }
}

export function setRestoId(restoId) {
  return {
    type: SET_RESTO_ID,
    payload: {
      restoId,
    }
  }
}

export function setQrId(qrId) {
  return {
    type: SET_QR_ID,
    payload: {
      qrId,
    }
  }
}

export function setUserId(userId) {
  return {
    type: SET_USER_ID,
    payload: {
      userId,
    }
  }
}
