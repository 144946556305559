/**
 * @format
 * @flow
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from 'react-bootstrap';
import Swal from 'sweetalert2';

import { CartType, ProductType } from '../flowObjectType'
import '../styles/ProductDetail.scss'
import { BsTrashFill } from "react-icons/bs";
import { FaRegTimesCircle } from "react-icons/fa"
import image1 from "../assets/allergen/1.png";
import image2 from "../assets/allergen/2.png";
import image3 from "../assets/allergen/3.png";
import image4 from "../assets/allergen/4.png";
import image5 from "../assets/allergen/5.png";
import image6 from "../assets/allergen/6.png";
import image7 from "../assets/allergen/7.png";
import image8 from "../assets/allergen/8.png";
import image9 from "../assets/allergen/9.png";
import image10 from "../assets/allergen/10.png";
import image11 from "../assets/allergen/11.png";
import image12 from "../assets/allergen/12.png";
import image13 from "../assets/allergen/13.png";
import image14 from "../assets/allergen/14.png";

const ImageData = {}
ImageData['gluten'] = image1
ImageData['arachide'] = image2
ImageData['coque'] = image3
ImageData['celeri'] = image4
ImageData['moutarde'] = image5
ImageData['oeuf'] = image6
ImageData['lait'] = image7
ImageData['poisson'] = image9
ImageData['crustace'] = image10
ImageData['mollusque'] = image11
ImageData['soja'] = image12
ImageData['anhydride'] = image13
ImageData['sesame'] = image8
ImageData['lupin'] = image14

const TextData = {}
TextData['gluten'] = "Contient du gluten"
TextData['arachide'] = "Contient des arachides"
TextData['coque'] = "Contient des fruits à coque"
TextData['celeri'] = "Contient du celeri"
TextData['moutarde'] = "Contient de la moutarde"
TextData['oeuf'] = "Contient des trace d'oeuf"
TextData['lait'] = "Contient du lait"
TextData['poisson'] = "Contient du poisson"
TextData['crustace'] = "Contient des crustacé"
TextData['mollusque'] = "Contient des mollusques"
TextData['soja'] = "Contient du soja"
TextData['anhydride'] = "Anhydride sulfureux"
TextData['sesame'] = "Contient du sésame"
TextData['lupin'] = "Contient du lupin"

const _ = require('lodash')

type State = {
  productQuantity: number
}

type Props = {
  show: boolean,
  closeModal: Function,
  addProduct: Function,
  updateProduct: Function,
  updateCartTotal: Function,
  deleteProduct: Function,
  myCart: CartType,
  dropdown: any,
  product: ProductType,
  updateIndex?: number,
  productQuantity?: number,
  isRestoDisabled?: boolean,
  clickAndCollect?: boolean,
  type?: number,
  demo: boolean,
  options: any,
  readOnly?: boolean,
  productHistory: Array<Object>,
}

class ProductDetail extends Component<Props, State> {
  dropdown: any
  hasRubric: boolean
  rubric: []
  total: number
  product

  constructor(props: any) {
    super(props)
    this.dropdown = this.props.dropdown
    this.rubric = []
    this.total = 0
    this.subtotal = 0
    this.options = this.props.options
    this.product = {}
  }

  state = {
    productQuantity: this.props.product.quantity ? this.props.product.quantity : 1
  }

  componentWillMount = () => {
    this.product = _.cloneDeep(this.props.product)
    if (this.product.rubric !== undefined && this.product.rubric.length) {
      this.rubric = _.cloneDeep(this.product.rubric)
    }



    if (this.product.rubrics && this.product.rubrics.length)
      this.rubric = this.getProductOptions(this.product)
    this.getProductPrice(this.product, this.state.productQuantity, false)
  }

  addQuantity = () => {
    if (this.state.productQuantity < 50) {
      if (this.verifyRoundQuantity(true)) return
      if (this.verifyMaxQuantity(true)) return
      this.getProductPrice(this.product, this.state.productQuantity + 1, false)
      this.setState((prevState) => ({ productQuantity: prevState.productQuantity + 1 }))
    }
  }

  removeQuantity = () => {
    if (this.state.productQuantity > 1) {
      this.getProductPrice(this.product, this.state.productQuantity - 1, false)
      this.setState((prevState) => ({ productQuantity: prevState.productQuantity - 1 }))
    }
  }

  getProductOptions = (product) => {
    const productRubrics = [];
    for (let i = 0; i < product.rubrics.length; ++i) {
      const platRubric = product.rubrics[i];

      for (let ii = 0; ii < this.options.length; ++ii) {
        const r = _.cloneDeep(this.options[ii])
        if (!r.shared && r.id === platRubric) {
          productRubrics.push(r);
        }
      }
    }
    return productRubrics;
  }

  selectRubric = (rubric: { checked: boolean }, checked: boolean, section: any) => () => {
    let totalSelectedQuantity = 0;
    for (let i = 0; i < section.data.length; ++i) {
      totalSelectedQuantity += section.data[i].quantity || 0;
    }
    if (totalSelectedQuantity === 0) {
      totalSelectedQuantity += section.selected
    }


    if (checked === true && totalSelectedQuantity >= section.selectMax && section.selectMax > 1) return

    if (checked === true) {
      if (section.selectMax === 1 && totalSelectedQuantity >= 1) { // online one choice
        section.data.forEach(choice => {
          choice.checked = false
        })
      }
      else {
        if (section.allowSameMultiple) {
          rubric.quantity = 1;
        }
        section.selected = section.selected ? section.selected + 1 : 1
      }
    }
    else {
      if (section.allowSameMultiple) {
        rubric.quantity = 0;
      }
      section.selected = section.selected ? section.selected - 1 : 0
    }
    rubric.checked = checked
    this.getProductPrice(this.product, this.state.productQuantity, false)
    this.forceUpdate()
  }

  rubricQuantityAdd = (rubric, section) => {
    let totalSelectedQuantity = 0;
    for (let i = 0; i < section.data.length; ++i) {
      totalSelectedQuantity += section.data[i].quantity || 0;
    }
    if (totalSelectedQuantity + 1 <= section.selectMax) {
      rubric.quantity += 1;
    }

    this.getProductPrice(this.product, this.state.productQuantity, false)
    this.forceUpdate();
  }

  rubricQuantityRemove = (rubric, section) => {
    if (rubric.quantity > 1) {
      rubric.quantity -= 1;
    }
    else {
      rubric.quantity = 0;
      rubric.checked = false;
    }

    this.getProductPrice(this.product, this.state.productQuantity, false)
    this.forceUpdate();
  }

  getRoundQuantity = () => {
    const products = this.props.myCart.products ? [].concat.apply([], Object.values(this.props.myCart.products)) : []
    let cartProducts = products ? products.filter(e => e.id === this.props.product.id) : [];
    let totalQuantity = cartProducts.length > 0 ? cartProducts.reduce((total, p) => total + p.quantity, 0) : 0;

    let allowedQuantity = (this.props.product.roundQuantity * this.props.cart.customerNb) - totalQuantity
    allowedQuantity = allowedQuantity > 0 ? allowedQuantity : 0

    return `${allowedQuantity} / ${this.props.product.roundQuantity * this.props.cart.customerNb}`
  }

  getMaxQuantity = () => {
    const products = this.props.myCart.products ? [].concat.apply([], Object.values(this.props.myCart.products)) : []
    let cartProducts = products ? products.filter(e => e.id === this.props.product.id) : [];
    let totalQuantity = cartProducts.length > 0 ? cartProducts.reduce((total, p) => total + p.quantity, 0) : 0;

    if (this.props.productHistory && this.props.productHistory[this.props.product.id])
      totalQuantity += this.props.productHistory[this.props.product.id]

    let allowedQuantity = (this.props.product.maxQuantity * this.props.cart.customerNb) - totalQuantity
    allowedQuantity = allowedQuantity > 0 ? allowedQuantity : 0
    return `${allowedQuantity} / ${this.props.product.maxQuantity * this.props.cart.customerNb}`
  }

  verifyRoundQuantity = (addProduct = false) => {
    const products = this.props.myCart.products ? [].concat.apply([], Object.values(this.props.myCart.products)) : []
    let cartProducts = products ? products.filter(e => e.id === this.props.product.id) : [];
    let totalQuantity = cartProducts.length > 0 ? cartProducts.reduce((total, p) => total + p.quantity, 0) : 0;

    if (this.props.updateIndex != null)
      totalQuantity -= this.props.product.quantity

    if (addProduct) {
      if (this.product.roundQuantity && this.state.productQuantity + totalQuantity >= this.product.roundQuantity * this.props.cart.customerNb) {
        return true
      }
      return false
    } // verify
    else if (this.product.roundQuantity && this.state.productQuantity + totalQuantity > this.product.roundQuantity * this.props.cart.customerNb) {
      return true
    }
    return false
  }

  verifyMaxQuantity = (addProduct = false) => {
    const products = this.props.myCart.products ? [].concat.apply([], Object.values(this.props.myCart.products)) : []
    let cartProducts = products ? products.filter(e => e.id === this.props.product.id) : [];
    let totalQuantity = cartProducts.length > 0 ? cartProducts.reduce((total, p) => total + p.quantity, 0) : 0;

    if (this.props.updateIndex != null)
      totalQuantity -= this.props.product.quantity

    if (this.props.productHistory && this.props.productHistory[this.props.product.id])
      totalQuantity += this.props.productHistory[this.props.product.id]


    if (addProduct) {
      if (this.product.maxQuantity && this.state.productQuantity + totalQuantity >= this.product.maxQuantity * this.props.cart.customerNb) {
        return true
      }
      return false
    } // verify
    else if (this.product.maxQuantity && this.state.productQuantity + totalQuantity > this.product.maxQuantity * this.props.cart.customerNb) {
      return true
    }
    return false

  }

  verifyProduct = () => {
    let error = false
    this.rubric.forEach((section) => {
      if (section.necessary === true && (typeof section.selected === 'undefined' || section.selected === 0)) {
        error = true
      }
    })
    return error
  }

  addProduct = async () => {
    if (this.verifyProduct()) {
      Swal.fire("Selection incomplète", "Veuillez selectionner les choix obligatoires", "error");
      return
    }
    if (this.verifyRoundQuantity()) {
      Swal.fire("Quantité maximum atteinte", "La quantité maximum par envoie pour ce produit est atteinte", "error");
      return
    }
    if (this.verifyMaxQuantity()) {
      Swal.fire("Quantité maximum atteinte", "La quantité maximum pour ce produit est atteinte", "error");
      return
    }

    const product = _.cloneDeep(this.product)

    const newProduct = {
      id: product.id,
      quantity: this.state.productQuantity,
      maxQuantity: product.maxQuantity || null,
      name: product.name,
      description: product.description ? product.description : '',
      unitPrice: product.unitPrice,
      discount: product.discount || null,
      tableDiscount: product.tableDiscount || null,
      image: product.image || null,
      rubric: _.cloneDeep(this.rubric),
      total: this.total,
      subtotal: this.subtotal,
      roundQuantity: product.roundQuantity || 0,
      allergene: product.allergene ?? []
    }

    await this.props.addProduct(newProduct)
    await this.getCartPrice()
    // this.dropdown.alertWithType('success', 'Produit ajouter', '');

    Swal.fire({
      position: 'top',
      icon: 'success',
      title: 'Produit ajouter',
      showConfirmButton: false,
      timer: 1000
    })
    this.props.closeModal()


  }

  updateProduct = async () => {
    if (this.verifyProduct()) {
      Swal.fire("Selection incomplète", "Veuillez selectionner les choix obligatoires", "error");
      return
    }

    const { product, updateIndex } = this.props
    const newProduct = {
      id: product.id,
      quantity: this.state.productQuantity,
      name: product.name,
      description: product.description ? product.description : '',
      unitPrice: product.unitPrice,
      discount: product.discount || null,
      tableDiscount: product.tableDiscount || null,
      image: product.image || null,
      rubric: this.rubric,
      total: this.total,
      subtotal: this.subtotal,
      roundQuantity: product.roundQuantity || 0
    }

    await this.props.updateProduct(newProduct, updateIndex, product.quantity)
    await this.getCartPrice()
    // this.dropdown.alertWithType('success', 'Panier modifier', '');
    this.props.closeModal()

  }

  getCartPrice = () => {
    const cart = this.props.myCart
    if (Object.keys(cart.products).length === 0) {
      this.props.updateCartTotal(0, 0, 0, 0, 0)
      return
    }
    let cartTotal = 0
    let cartTotalProducts = 0
    let cartTotalFreeProducts = 0
    let cartSubtotal = 0

    const products = cart.products ? [].concat.apply([], Object.values(cart.products)) : []
    products.forEach((product) => {
      const { total, subtotal } = this.getProductPrice(product, product.quantity, true)
      cartTotal += total
      cartTotalProducts += product.quantity
      cartSubtotal += subtotal
      if (total === 0) {
        cartTotalFreeProducts += product.quantity
      }
    })

    this.props.updateCartTotal(cartTotal, cartTotalProducts, cartSubtotal, cartTotalFreeProducts)
  }


  getProductPrice = (product: ProductType, quantity: number, forTotal: boolean): { total: number, subtotal: number } => {
    const rubric = forTotal ? product.rubric : this.rubric

    if (typeof product === 'undefined') return { total: 0 }
    let total = quantity * product.unitPrice

    rubric?.forEach((section) => {
      section.data.forEach((rubric) => {
        if (rubric.checked && rubric.unitPrice > 0) {
          if (section.allowSameMultiple) {
            total += quantity * rubric.unitPrice * (rubric.quantity || 0)
          }
          else {
            total += quantity * rubric.unitPrice
          }
        }
      })
    })

    if (product.tableDiscount && product.tableDiscount.discount) {
      total = total * (1 - product.tableDiscount.discount / 100)
    }

    let subtotal = total

    // apply cart discount
    if (this.props.myCart.discount) {
      if (!product.tableDiscount || (product.tableDiscount && product.tableDiscount.canDiscount)) {
        total = total * (1 - this.props.myCart.discount / 100)
      }
    }

    this.total = total
    this.subtotal = subtotal
    return { total, subtotal }
  }

  deleteProduct = async () => {

    const { updateIndex } = this.props
    this.props.deleteProduct(updateIndex, this.props.product)
    this.getCartPrice()
    // this.dropdown.alertWithType('success', 'Produit supprimer');
    this.props.closeModal()
  }

  deleteProductAlert = () => {
    Swal.fire({
      title: 'Confirmer la suppression ?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Oui supprimer du panier',
      denyButtonText: `Non`,
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        this.deleteProduct()
      }
    })
  }

  getLabel = () => {
    if (this.props.demo)
      return 'Compte demo'
    if (this.props.isRestoDisabled)
      return 'Restaurant fermé'
    if (!this.product.displayApp)
      return 'Produit indisponible'
    return `Ajouter au panier (${this.subtotal.toFixed(2)} €)`
  }

  renderProductInfo = () => {
    const { product } = this.props
    if (typeof product === 'undefined') return <div />
    const hasImage = typeof product.image !== 'undefined' && product.image.path !== '' && product.image.enable
    const unitPrice = parseFloat(product.unitPrice);

    let discountPrice = ``;
    let hasDiscount = false;
    let discountLabel = '';
    if (product.tableDiscount && product.tableDiscount.discount) {
      discountPrice = unitPrice * (1 - product.tableDiscount.discount / 100)
      hasDiscount = true;
      discountLabel = `${product.tableDiscount.discount}% remise immédiate `
    }
    const isFidelityDiscount = hasDiscount ? product.discount && product.discount.isFidelityDiscount : false

    return (
      <div className="product-info-block">

        <div className="back-btn" onClick={this.props.closeModal}>
          <FaRegTimesCircle size="3em" color={'#000'} />
        </div>

        {typeof this.props.updateIndex !== 'undefined' && (
          <button className="delete-btn" onClick={this.deleteProductAlert}><BsTrashFill size="1.2em" /></button>
        )}
        {hasImage && (

          <img
            alt="produit"
            className="product-img"
            src={product.image.path}
          />
        )}

        {!hasImage &&
          <div style={{ height: 50 }} />
        }


        <div className="productName">
          {this.product.name}
        </div>

        <div className="quantity-information">
          {this.product.roundQuantity > 0 && (
            <div className="product-round-quantity">
              <span className="round-quantity-label">Restant par envoie</span>
              <div className="round-quantity">{this.getRoundQuantity()}</div>
            </div>
          )}

          {this.product.maxQuantity > 0 && (
            <div className="product-round-quantity">
              <span className="round-quantity-label">Restant total</span>
              <div className="round-quantity">{this.getMaxQuantity()}</div>
            </div>
          )}
        </div>

        <div />

        {product.description != null && product.description.trim() !== '' && (
          <div className="product-description">{product.description}</div>
        )}

        {hasDiscount && !isFidelityDiscount && (
          <div className="product-price" style={{ alignItems: 'center' }}>
            <span style={{ textDecoration: 'line-through' }}>{unitPrice.toFixed(2)} €</span>
            <span> {discountPrice.toFixed(2)} €</span>
            <div style={{ flexDirection: 'row' }}>
              <span>{discountLabel}</span>
              {/* <img
                source={require('../../assets/icon/banking.png')}
                resizeMode="contain"
                style={{ width: 25, height: 25 }}
              /> */}
            </div>
          </div>
        )}

        {!hasDiscount && product.unitPrice > 0 && (
          <div className="product-price">{unitPrice.toFixed(2)} €</div>
        )}
      </div>
    )
  }

  renderContent = () => {
    return (
      <div className="content-block">
        {this.rubric.map((section) => {
          const sectionHeader = this.renderSectionHeader({ section })
          return section.data.map((item, idx) => {
            const sectionItem = this.renderRubric({ item, index: section.index, section })
            if (idx === 0) return <div key={section.index + section.rubricName}>{sectionHeader}{sectionItem}</div>
            return sectionItem
          })
        })}
      </div>
    )
  }

  renderAllergen = () => {
    const { product } = this.props
    const allergen = product.allergene ?? []
    return (
      <div className="allergen-block">
        {allergen.map((name) =>
          <div className="allergen-detail" key={name}>
            <img
              alt="produit"
              className="product-img"
              src={ImageData[`${name}`]}
              width={30}
              height={30}
            />
            <div className="allergen-name">{TextData[`${name}`]}</div>
          </div>
        )}
      </div>
    )
  }

  renderSectionHeader = ({ section }: { section: any }) => {
    const { rubricName, selectMin, selectMax, necessary } = section
    let name = ''
    if (selectMin === selectMax && selectMax !== 1)
      name = `${selectMin} ${rubricName} `
    else if (selectMax !== 1)
      name = `${rubricName} (${selectMax} maximum)`
    else
      name = rubricName
    return (
      <div
        className="section-header"
        key={section.rubricName + section.index}
      >
        <div>{name} {necessary && (' ( obligatoire )')}</div>
      </div>

    )
  }

  renderRubric = ({ item, index, section }: { item: any, index: number, section: any }) => {
    if (item.displayApp === false) return <div />
    const unitPrice = item.unitPrice ? `+ ${parseFloat(item.unitPrice).toFixed(2)} €` : ''
    return (
      <div
        className="rubricItem"
        onClick={this.selectRubric(item, !item.checked, section)}
        key={section.index + item.name}
      >

        {!this.props.readOnly && (
          <div className="ui radio checkbox">
            <Form.Check
              size={"lg"}
              checked={item.checked || false}
              type={section.necessary && section.selectMax === 1 ? 'radio' : 'checkbox'}
              onChange={() => { }}
              color="red"
            />
          </div>
        )}
        <div className="rubric-name">
          {item.name.startsWith('www.') && (
            <span><a target="_blank" rel="noopener noreferrer" href={`//${item.name}`}>{item.name}</a></span>
          )}
          {!item.name.startsWith('www.') && (
            <span>{item.name}</span>
          )}
        </div>
        <div className="rubric-price">
          <span>{unitPrice}</span>
        </div>

        {section.allowSameMultiple && item.checked && (
          <div className="rubric-quantity-container" onClick={(e) => e.stopPropagation()}>
            <span className="rubric-quantity-control" onClick={() => this.rubricQuantityRemove(item, section)}>-</span>
            <span className="rubric-quantity-number">{item.quantity}</span>
            <span className="rubric-quantity-control" onClick={() => this.rubricQuantityAdd(item, section)}>+</span>
          </div>
        )}
      </div>
    )
  }

  renderOption = ({ item, index, section }: { item: any, index: number, section: any }) => {
    if (item.displayApp === false) return <div />
    const unitPrice = item.unitPrice ? `+ ${parseFloat(item.unitPrice).toFixed(2)} €` : ''
    return (
      <div
        className="rubricItem"
        onClick={this.selectRubric(item, !item.checked, section)}
        key={section.index + item.name}
      >
        <div>
          <Form.Check
            size={"lg"}
            checked={item.checked || false}
            type={item.necessary ? 'radio' : 'checkbox'}
            onChange={() => { }}
          />
        </div>
        <div className="rubric-name">
          <span>{item.name}</span>
        </div>
        <div className="rubric-price">
          <span>{unitPrice}</span>
        </div>
      </div>
    )

  }

  renderFooter = () => {
    const { product } = this.props
    if (typeof product === 'undefined') return <div />
    return (
      <div>
        {this.props.updateIndex == null && (
          <div className="modalFooter">

            <div className='footer'>
              <div className="quantityBlock">
                <button className="quantityBtn" onClick={this.removeQuantity}>-</button>
                <button className="totalQty">{this.state.productQuantity}</button>
                <button className="quantityBtn" onClick={this.addQuantity}>+</button>
              </div>

              <div className="footer-btn" onClick={this.addProduct}>
                {this.getLabel()}
              </div>
            </div>
          </div>
        )}

        {this.props.updateIndex != null && (
          <div className="modalFooter">

            <div className='footer'>
              <div className="quantityBlock">
                <button className="quantityBtn" onClick={this.removeQuantity}>-</button>
                <button className="totalQty">{this.state.productQuantity}</button>
                <button className="quantityBtn" onClick={this.addQuantity}>+</button>
              </div>
              <div className="footer-btn" onClick={this.updateProduct}>
                {`Mettre à jour (${this.subtotal.toFixed(2)} €)`}
              </div>
            </div>
          </div>
        )}
      </div>
    )
  }

  render() {
    return (
      <div className="modalContainer">
        <div className="scrollable-content">
          {this.renderProductInfo()}
          {this.renderContent()}
          {this.renderAllergen()}
        </div>
        {!this.props.readOnly && (
          this.renderFooter()
        )}
      </div>
    );
  }
}

// redux
const mapStateToProps = (state) => {
  return {
    cart: state.cart
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    // addProduct: (product) => dispatch(addProduct(product)),
    // updateProduct: (product, idx) => dispatch(updateProduct(product, idx)),
    // updateCartTotal: (cartTotal, cartTotalHT, cartTotalVat, cartTotalProducts, cartSubtotal) => dispatch(updateCartTotal(cartTotal, cartTotalHT, cartTotalVat, cartTotalProducts, cartSubtotal)),
    // deleteProduct: (idx) => dispatch(deleteProduct(idx))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductDetail);